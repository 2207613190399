// globals
$DEFAULT_PAGE_BG_COLOR: #e2e2e2;
$DEFAULT_ERROR_COLOR: #eb2f39;
$DEFAULT_FONT: 'Noto Serif KR';
$DEFAULT_BOLD_FONT: 'Noto Serif KR';
$DEFAULT_PAGE_WIDTH: 90%;
$DEFAULT_BORDER_COLOR: #3e3e3e;
$FOCUSED_BORDER_COLOR: #9b8e8e;

$DEFAULT_BOX_SHADOW: 3px 3px 5px $DEFAULT_PAGE_BG_COLOR;
$DEFAULT_BOX_SHADOW_FOCUSED: 3px 3px 5px #8a7e73;

$DEFAULT_ACCENT_COLOR: #3e3e3e;
$DEFAULT_ACCENT_COLOR_DARK: #2c2c2c;
$DEFAULT_LOW_ACCENT_COLOR: #525252;
$DEFAULT_LINK_COLOR: #0460c9;

$DEFAULT_DROPDOWN_FOCUS_COLOR: #d4cbc3;

$DROP_SHADOW: drop-shadow(5px 5px 2px #cfc3b9);
$DEFAULT_INPUT_HEIGHT: 40px;
$DEFAULT_INPUT_PADDING: 20px;
$DEFAULT_INPUT_TOTAL_PADDING: 40px; // 2 times padding
$DEFAULT_INPUT_WITH_RESULTS_BORDER_RADIUS: 10px;

$MOBILE_PAGE_WIDTH: 768px;