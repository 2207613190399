@import '../../styles.scss';

.defaultLink {
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: .2s all ease;
    padding-bottom: 2px;
    font-family: $DEFAULT_FONT;
    font-size: 0.9em;
}

.defaultLinkSelected {
    border-color: $DEFAULT_ACCENT_COLOR;
}

.defaultLink:hover {
    border-color: $DEFAULT_ACCENT_COLOR;
    color: $DEFAULT_ACCENT_COLOR;
    cursor: pointer;
}