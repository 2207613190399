@import '../../styles.scss';

.createMemoryModalBackground {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #bdbdbd9a;

    opacity: 0;
    animation: fade-in 0.3s ease forwards;
}

.createMemoryModalContainer {
    width: 80%;
    background-color: $DEFAULT_PAGE_BG_COLOR;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    max-height: 90vh;
    overflow-y: scroll;
    transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    box-shadow: 5px 5px 5px #92877d;

    opacity: 0;
    animation: fade-in 0.3s ease forwards;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.createMemoryModalContainer::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.createMemoryModalContainer {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.createMemoryModalContentContainer {
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.createMemoryModalLinkContainer {
    display: inline-block;
    color: $DEFAULT_LOW_ACCENT_COLOR;
    transition: .2s all ease;
    cursor: pointer;
}

.createMemoryModalLinkContainer:hover {
    color: $DEFAULT_LINK_COLOR;
}

.createMemoryModalLinkImg {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    float: left;
    position: relative;
}

.createMemoryModalLink {
    float: left;
    position: relative;
}

.createMemoryModalTitleInput {
    border: none;
    outline: none;
    font-size: 1.3em;
    font-family: $DEFAULT_BOLD_FONT;
    font-weight: 700;
    width: 100%;
    margin-top: 0px;
    line-height: 30px;
    background-color: transparent;
}

.createMemoryModalSubheaderContainer {
    margin-top: 25px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.createMemoryModalTopicSubheaderContainer {
    margin-bottom: 0px;
}

.createMemoryModalSubheader {
}

.createMemoryModalTopicsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.createMemoryModalTopic {
    margin-right: 5px;
    margin-bottom: 10px;
}

.createMemoryModalTopicInputError {
    color: $DEFAULT_ERROR_COLOR;
    margin-top: 5px;
}

.createMemoryModalTagInput {
    width: 100%;
}

.createMemoryModalTagContainer {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    flex-wrap: wrap;
}

.createMemoryModalTag {
    margin-right: 5px;
    margin-top: 10px;
}

.createMemoryModalActionButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
    margin-top: 30px;
}

.createMemoryModalCancelButton {

}

.createMemoryModalSaveButton {
    margin-left: 10px;
}

.createModalErrorContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.createModalErrorText {
    margin-bottom: 20px;
}

.createMemoryModalImgContainer {
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 30vh;
    max-height: 400px;
}

.createMemoryModalImg {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%
}

.createMemoryModalExtraActionText {
    color: $DEFAULT_LOW_ACCENT_COLOR;
    cursor: pointer;
    display: inline-block;
}

.createMemoryModalExtraActionText:hover {
    color: $DEFAULT_LINK_COLOR;
}

.createMemoryModalLoaderContainer {
    height: 30vh;
}

.createMemoryModalClose {
    width: 30px;
    height: auto;
    margin-left: -5px;
    margin-bottom: 20px;
    color: $DEFAULT_ACCENT_COLOR;
    cursor: pointer;
}

@keyframes fade-in-bg-create-modal {
    0% { opacity: 0; }
    100% { opacity: 0.1; }
}

@media screen and (max-width: $MOBILE_PAGE_WIDTH) {
    .createMemoryModalContainer {
        width: 100%;
        height: 100%;
        max-height: 100vh;
    }
}