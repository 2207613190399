@import '../styles.scss';

.homeScreenContainer {
    padding-bottom: 50px;
}

.homeScreenContentHeaderContainer {
    display: flex;
    flex-direction: row;
    width: $DEFAULT_PAGE_WIDTH;
    justify-content: space-between;
    align-items: center;
    margin: 0px auto;
    margin-top: 70px;
}

.noMemoriesInfoContainer {
    width: 40%;
    margin: 0px auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.noMemoriesBlobContainer {
    position: relative;
    width: 15vw;
    height: 15vw;
    margin-bottom: 40px;
    margin-top: 8vh;
    opacity: 0;
    animation: load-from-left 1s ease forwards;
}

.noMemoriesBlob {
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0.5;
}

.noMemoriesInfoText {
    font-size: 1.9em;
}

.noMemoriesInfoBlurb {
    margin-top: 10px;
    font-size: 1.2em;
}

.homeScreenAddMemoryInputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 800px;
    width: 40%;
}

.homeScreenAddMemoryInputContainerMobile {
    width: 90%;
    margin: 0px auto;
    top: 40px;
    position: relative;
}

.homeScreenAddMemoryInputContainerNoMemories {
    margin: 0px auto;
    width: 80%;
}

.homeScreenAddMemoryInput {
    flex: 1;
}

.homeScreenAddImage {
    width: 30px;
    height: 30px;
    color: #636363;
    transition: .2s all ease;
    margin-left: 20px;
    cursor: pointer;
}

.homeScreenAddImage:hover {
    color: $DEFAULT_BORDER_COLOR;
}

.homeScreenFileInputContainer {

}

.homeScreenFileInput {
    display: none;
}

.homeScreenSortContainer {
    display: flex;
    align-items: center;
}

.homeScreenSortText {
    margin-right: 15px;
}

.homeScreenUseExtensionText {
    text-align: center;
    margin: 0px auto;
    display: block;
    margin-top: 20px;
    cursor: pointer;
    color: $DEFAULT_LINK_COLOR;
    max-width: 80%;
}

.noMemoriesExampleButton {
    margin-left: 20px;
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
}

@media screen and (max-width: $MOBILE_PAGE_WIDTH) {
    .noMemoriesBlobContainer {
        width: 150px;
        height: 150px;
    }

    .noMemoriesInfoContainer {
        width: 80%;
    }

    .homeScreenAddMemoryInput {
        width: 80%;
    }

    .homeScreenAddMemoryInputContainerNoMemories {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .noMemoriesExampleButton {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}