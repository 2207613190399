@import '../../styles.scss';

.defaultNavBarContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: $DEFAULT_PAGE_WIDTH;
    margin: 0px auto;
    padding-top: 30px;
}

.defaultNavBarRightContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.defaultNavBarProfileImgContainer {
    position: relative;
}

.defaultNavBarProfileImgIconContainer {
    position: relative;
    width: 50px;
    height: 50px;
    margin-left: 30px;
}

.defaultNavBarProfileImg {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    cursor: pointer;
    transition: .2s all ease;
    position: absolute;
    top: 0px;
    left: 0px;
}

.defaultNavBarProfileImg:hover {
    transform: scale(1.1);
}

.defaultNavBarProfileMenu {
    z-index: 10;
    position: absolute;
    top: 50px;
    right: 0px;
    background-color: $DEFAULT_PAGE_BG_COLOR;
    width: 250px;
    margin-top: 10px;
    transition: all .2s ease;
    border: 1px solid $DEFAULT_BORDER_COLOR;
    box-shadow: $DEFAULT_BOX_SHADOW_FOCUSED;
}

.defaultNavBarMenuItem {
    transition: all .2s ease;
    display: flex;
    align-items: center;
}

.defaultNavBarMenuItem:hover {
    background-color: $DEFAULT_DROPDOWN_FOCUS_COLOR;
    cursor: pointer;
}

.defaultNavBarMenuIcon {
    margin-left: 10px;
    width: 15px;
}

.defaultNavBarMenuText {
    padding: 10px;
}

.defaultNavBarProfileNoImg {
    background-color: $DEFAULT_ACCENT_COLOR;
    color: $DEFAULT_PAGE_BG_COLOR;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}