@import './styles/styles.scss';

html {
    background-color: $DEFAULT_PAGE_BG_COLOR;
}

html, body {
    max-width: 100%;
    overflow-x: hidden;
}

table, th, td {
    border: 1px solid $DEFAULT_ACCENT_COLOR;
    border-collapse: collapse;
}

.mementoLogo {
    width: 100px;
}

.mainBgImg {
    position: fixed;
    z-index: -1;
    top: 0px;
    left: 0px;
    width: 100%;
    min-width: 760px;
    height: 100%;
    opacity: 0;
    animation: fade-in-main-bg 2.5s ease forwards;
}

@keyframes load-from-left {
    0% { opacity: 0; transform: translateX(-5px) }
    100% { opacity: 1; transform: translateX(0px) }
}

@keyframes load-from-right {
    0% { opacity: 0; transform: translateX(5px) }
    100% { opacity: 1; transform: translateX(0px) }
}

@keyframes fade-in-main-bg {
    0% { opacity: 0; }
    100% { opacity: 0.1; }
}

@keyframes fade-in {
    0% { opacity: 0; }
    100% { opacity: 1; }
}