@import '../styles.scss';

.addTagContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 2px solid $DEFAULT_ACCENT_COLOR;
    background-color: $DEFAULT_ACCENT_COLOR;
    padding-left: 15px;
    padding-right: 15px;
    height: 30px;
    border-radius: 40px;
    transition: .2s all ease;
    max-width: 80%;
}

.addTagText {
    margin-left: 10px;
    margin-right: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    white-space: nowrap;
    color: $DEFAULT_PAGE_BG_COLOR;
}

.addTagCloseImg {
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-top: 4px;
    color: $DEFAULT_PAGE_BG_COLOR;
}