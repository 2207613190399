@import '../../styles.scss';

.viewMemoryModalDeleteButton {
    background-color: #ca1a29;
    border-color: #ca1a29;
}

.viewMemoryModalDeleteButton:hover {
    background-color: #ac2f35;
    border-color: #ac2f35;
}

.viewMemoryModalDeleteIcon {
    width: 15px;
    height: auto;
    margin-right: 5px;
}

.viewMemoryModalSelectedTopic {
    display: flex;
    justify-content: center;
    align-items: center;
}

.viewMemoryModalButtonText {
    font-family: $DEFAULT_BOLD_FONT;
    color: #fff;
    font-weight: 500;
}