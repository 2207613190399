@import '../../styles.scss';

.defaultImageLoadingContainer {
    background-color: #dbd0c7;
    min-height: 100%;
    min-width: 100%;
}

.defaultImage {
    opacity: 0;
    animation: fade-in .5s ease forwards;
}