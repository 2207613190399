@import '../styles.scss';

.loginScreenContainer {
    width: 80%;
    margin: 0px auto;
}

.loginScreenHeaderContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    opacity: 0;
    animation: load-from-right 1s ease forwards;
}

.loginScreenMidContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.loginScreenMidContainerText {
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
    transition: .2s all ease;
    padding-bottom: 2px;
    border-bottom: 1px solid $DEFAULT_PAGE_BG_COLOR;
}

.loginScreenMidContainerText:hover {
    color: $DEFAULT_ACCENT_COLOR_DARK;
    border-bottom-color: $DEFAULT_ACCENT_COLOR_DARK;
}

.loginScreenLoginButton {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
}

.loginScreenLoginButtonText {
    margin-bottom: 3px;
}

.loginScreenLoginButton:hover {
    cursor: pointer;
}

.loginScreenGoogleIcon {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.loginScreenInfoContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: 0px auto;
    height: 100vh;
    position: relative;
}

.loginScreenFaceIllustrationContainer {
    width: 70%;
    position: relative;
}

.loginScreenFaceIllustration {
    width: 100%;
    opacity: 0;
    animation: fade-in-partial 1s ease forwards;
}

.loginScreenBlob {
    position: absolute;
    z-index: -1;
    top: 0px;
    left: 0px;
    opacity: 0;
    animation: fade-in-partial 5s 0.5s ease forwards, fade-in-out 10s 5.5s linear infinite;
}

.loginScreenBlob1 {
    left: 23%;
    top: 16%;
}

.loginScreenBlob2 {
    left: 42%;
    top: 46%;
    animation: fade-in-partial 5s 1s ease forwards, fade-in-out 10s 6s linear infinite;
}

.loginScreenInfoContainerLeft {
}

.loginScreenWelcomeHeader {
    font-size: 2.8em;
    position: relative;
    margin-bottom: 30px;
    opacity: 0;
    animation: load-from-left 1s ease forwards;
}

.loginScreenWelcomeBlurb {
    line-height: 2em;
    border-left: 3px solid $DEFAULT_ACCENT_COLOR_DARK;
    padding-left: 10px;
    font-size: 1.2em;
    opacity: 0;
    animation: load-from-left 1s 0.2s ease forwards;
    width: 80%;
    margin-bottom: 30px;
}

.loginScreenGetStartedButton {
    width: 250px;
    height: 50px;
    opacity: 0;
    animation: load-from-left 1s 0.4s ease forwards;
}

.loginScreenWelcomeExtraFooter {
    margin-top: 20px;
    opacity: 0;
    animation: load-from-left 1s 0.4s ease forwards;
}

.loginScreenGetStartedButtonText {
    font-size: 1.2em;
}

.loginScreenSectionContainer {
    padding-top: 50px;
    margin-bottom: 100px;
}

.loginScreenSectionHeaderContainer {
    text-align: center;
}

.loginScreenSectionHeaderText {
    color: $DEFAULT_ACCENT_COLOR_DARK;
    font-size: 1.3em;
}

.loginScreenSectionHeaderBlurb {
    font-size: 1.9em;
}

.loginScreenPreviewImgContainer {
    position: relative;
}

.loginScreenPreviewImg {
    width: 70%;
    display: block;
    margin: 0px auto;
}

.loginScreenPreviewBlob1 {
    position: absolute;
    z-index: -1;
    top: -10%;
    left: 10%;
    width: 80%;
    opacity: 0.5;
}

.loginScreenPreviewBlob2 {
    position: absolute;
    z-index: -1;
    top: 75%;
    right: -25%;
    width: 50%;
    opacity: 0.5;
}

.loginScreenHowItWorksGrid {
    display: grid;
    grid-template-rows: 50% 50%;
    grid-template-columns: 50% 50%;
    width: 60%;
    margin: 0px auto;
}

.loginScreenHowItWorksGridElement {
    margin: 50px;
}

.loginScreenHowItWorksGridHeader {
    font-size: 1.3em;
    color: $DEFAULT_ACCENT_COLOR_DARK;
    margin-bottom: 20px;
}

.loginScreenHowItWorksGridBlurb {
    font-size: 1.1em;
}

.loginScreenEmailBlurb {
    margin-top: 20px;
    font-size: 1.1em;
}

.loginScreenStepContainer {
    height: 200px;
}

.signUpEmailContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    min-width: 300px;
    opacity: 0;
    animation: load-from-right 1s ease forwards;
}

.signUpEmailInput {
    flex: 1;
}

.signUpEmailRequestButton {
    margin-left: 20px;
    width: 140px;
}

.signUpEmailInputError {
    color: $DEFAULT_ERROR_COLOR;
    margin-top: 5px;
    height: 20px;
}

@media screen and (max-width: 1700px) {
    .loginScreenInfoContainer {
        width: 100%;
    }
}

@media screen and (max-width: 1400px){
    .loginScreenContainer {
        width: 90%
    }

    .loginScreenHowItWorksGrid {
        width: 100%;
    }
}

@media screen and (max-width: 950px) {
    .loginScreenFaceIllustrationContainer {
        display: none;
        visibility: hidden;
    }
}

@media screen and (max-width: $MOBILE_PAGE_WIDTH) {
    .loginScreenMidContainer {
        display: none;
    }

    .loginScreenHowItWorksGrid {
        width: 100%;
        grid-template-rows: unset;
        grid-template-columns: 100%;
    }
    
    .loginScreenHowItWorksGridHeader {
        width: 80%;
        margin: 0px auto;
        margin-bottom: 25px;
    }
    
    .loginScreenHowItWorksGridElement {
        margin: 0px;
        text-align: center;
        margin-top: 50px;
    }
}

@keyframes fade-in-partial {
    0% { opacity: 0; }
    100% { opacity: 0.8; }
}

@keyframes fade-in-out {
    0% { opacity: 0.8; }
    50% { opacity: 0.4; }
    100% { opacity: 0.8; }
}