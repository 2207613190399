@import '../styles.scss';

.adminScreen {
    padding: 20px;
    margin: 20px;
}

.adminScreenGoHome {
    cursor: pointer;
    display: inline-block;
    margin-bottom: 20px;
}

.adminContainer {
    border: 1px solid $DEFAULT_ACCENT_COLOR;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    margin-bottom: 20px;
}

.adminContainerHeader {
    font-size: 20px;
    margin-bottom: 10px;
}

.queryRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 50%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.adminCustomHTMLInput {
    width: 80%;
    margin-top: 20px;
}

.adminCustomEmailError {
    color: $DEFAULT_ERROR_COLOR;
    margin-top: 20px;
    margin-bottom: 20px;
}

.adminContainerUserTable {
    text-align: left;
    width: 80%;
}

.adminInviteUserButton {
    border-radius: 0px;
    width: 100%;
}

.adminContainerRequestInviteError {
    color: $DEFAULT_ERROR_COLOR;
    margin-top: 10px;
}

.adminContainerRequestInviteInput {
    width: 80%;
}