@import '../../styles.scss';

.defaultTextAreaContainer {
    position: relative;
    width: 200px;
}

.defaultTextArea {
    width: calc(100% - #{$DEFAULT_INPUT_TOTAL_PADDING}); // remove padding
    height: 200px;
    outline: none;
    padding: $DEFAULT_INPUT_PADDING;
    transition: .2s all ease;
    font-family: $DEFAULT_FONT;
    font-size: 0.9em;
    background-color: transparent;
    border: 1px solid $DEFAULT_BORDER_COLOR;
    border-radius: 5px;
    box-shadow: $DEFAULT_BOX_SHADOW;
}

.defaultTextArea:focus {
    box-shadow: $DEFAULT_BOX_SHADOW_FOCUSED;
}