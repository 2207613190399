@import '../../styles.scss';

.inviteUserModalHeader {
    font-size: 1.3em;
    margin-bottom: 20px;
}

.inviteUserModalInvitedEmail {

}

.inviteUserModalInviteButton {
    margin-left: 10px;
    margin-right: 10px;
}

.inviteUserModalInviteInput {
    width: 100%;
    margin-top: 20px;
}

.inviteUserModalError {
    color: $DEFAULT_ERROR_COLOR;
    height: 30px;
    margin-top: 10px;
}