@import '../../styles.scss';

.defaultButton {
    width: 100px;
    height: 40px;
    border-radius: 40px;
    border: 2px solid $DEFAULT_ACCENT_COLOR;
    background-color: $DEFAULT_ACCENT_COLOR;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    outline: none;
    transition: .2s all ease;
}

.defaultActiveButton:hover {
    cursor: pointer;
    background-color: $DEFAULT_ACCENT_COLOR_DARK;
    border: 2px solid $DEFAULT_ACCENT_COLOR_DARK;
}

.defaultInactiveButton {
    background-color: #c9bbaf;
    border-color: #c9bbaf;

    .defaultButtonText {
        color: #757575;
    }
}

.defaultButtonText {
    color: #fff;
    font-size: 0.9em;
    top: -2px;
    position: relative;
}