@import '../styles.scss';

.memoryGridSortHeader {
    width: $DEFAULT_PAGE_WIDTH;
    margin: 0px auto;
    font-size: 1.5em;
    margin-top: 20px;
    color: $DEFAULT_ACCENT_COLOR_DARK;
}

.memoryGrid {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(250px,1fr));
    grid-auto-rows: 200px;
    width: $DEFAULT_PAGE_WIDTH;
    margin: 0px auto;
    margin-top: 30px;
    padding-bottom: 50px;
}

.infinite-scroll-component {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.infinite-scroll-component::-webkit-scrollbar {
    display: none;
}

.memoryGridCard {
}

.memoryGridURLCard {
    grid-row-end: span 1;
}

.memoryGridImgCard {
    grid-row-end: span 2;
}

.memoryGridLoaderContainer {
    position: relative;
    height: 30px;
}