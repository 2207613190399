@import '../styles.scss';

.selectTopicContainer {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    border: 2px dashed $DEFAULT_ACCENT_COLOR;
    padding-left: 15px;
    padding-right: 15px;
    height: 30px;
    border-radius: 40px;
    cursor: pointer;
    transition: .2s all ease;
    max-width: 80%;
}

.selectTopicContainer:hover {
    border: 2px solid $DEFAULT_ACCENT_COLOR;
    background-color: $DEFAULT_ACCENT_COLOR;
    color: $DEFAULT_PAGE_BG_COLOR;

    .selectTopicPercentage {
        color: $DEFAULT_PAGE_BG_COLOR;
    }
}

.selectTopicContainerSelected {
    border: 2px solid $DEFAULT_ACCENT_COLOR;
    background-color: $DEFAULT_ACCENT_COLOR;
    color: $DEFAULT_PAGE_BG_COLOR;

    .selectTopicPercentage {
        color: $DEFAULT_PAGE_BG_COLOR;
    }
}

.selectTopicText {
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    white-space: nowrap;
    position: relative;
    top: -2px;
}

.selectTopicPercentage {
    margin-left: 7px;
    font-size: 0.7em;
}

.selectTopicPercentageRed {
    color: #eb2f39;
}

.selectTopicPercentageYellow {
    color: #e88e0c;
}

.selectTopicPercentageGreen {
    color: #298743;
}