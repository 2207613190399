@import '../styles.scss';

.memoryCard {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #8a7e73;
    padding: 20px;
    box-shadow: $DEFAULT_BOX_SHADOW;
    transition: .2s all ease;
    position: relative;
    border-radius: 5px;
}

.memoryCard:hover {
    cursor: pointer;
    box-shadow: $DEFAULT_BOX_SHADOW_FOCUSED;
}

.memoryCardURL {
    font-size: 0.8em;
}

.memoryCardTitle {
    font-size: 0.9em;
    line-height: 1.4em;
    margin-top: 5px;
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

.memoryCardTopic {
    text-align: right;
    font-size: 0.8em;
    margin-top: 10px;
}

.memoryCardImgContainer {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

@keyframes fade-in-bg {
    0% { opacity: 0; }
    100% { opacity: 0.2; }
}