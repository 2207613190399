@import '../../styles.scss';

.feedbackModalHeader {
    font-size: 1.3em;
    margin-bottom: 20px;
}

.feedbackModalBlurb {
    margin-bottom: 20px;
}

.feedbackModalTextArea {
    width: 100%;
}

.feedbackModalMsg {
    margin-top: 20px;
}

.feedbackModalButton {
    margin-left: 10px;
    margin-right: 10px;
}