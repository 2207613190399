@import '../styles.scss';

.unauthorizedScreenContainer {
    position: absolute;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
}

.unauthorizedScreenContainerHeader {
    font-size: 1.9em;
}

.unauthorizedScreenContainerBlurb {
    font-size: 1.2em;
    margin-top: 10px;
}

.unauthorizedScreenContainerButton {
    margin-top: 30px;
    width: 200px;
}