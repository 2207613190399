@import '../../styles.scss';

.defaultInputContainer {
    position: relative;
    width: 200px;
}

.defaultTextInput {
    width: calc(100% - #{$DEFAULT_INPUT_TOTAL_PADDING}); // remove padding
    height: $DEFAULT_INPUT_HEIGHT;
    outline: none;
    padding-left: $DEFAULT_INPUT_PADDING;
    padding-right: $DEFAULT_INPUT_PADDING;
    transition: .2s all ease;
    line-height: 60px;
    font-family: $DEFAULT_FONT;
    font-size: 0.9em;
    background-color: transparent;
    border: 1px solid $DEFAULT_BORDER_COLOR;
    border-radius: 5px;
    box-shadow: $DEFAULT_BOX_SHADOW;
}

.defaultTextInputWithResults {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: $DEFAULT_INPUT_WITH_RESULTS_BORDER_RADIUS;
    border-top-right-radius: $DEFAULT_INPUT_WITH_RESULTS_BORDER_RADIUS;
}

.defaultTextInput:focus {
    box-shadow: $DEFAULT_BOX_SHADOW_FOCUSED;
}

.defaultInputSearchResults {
    position: absolute;
    top: $DEFAULT_INPUT_HEIGHT + 'px';
    left: 0px;
    width: 100%;
    max-height: 200px;
    overflow-y: scroll;
    background-color: $DEFAULT_PAGE_BG_COLOR;
    border-left: 2px solid $FOCUSED_BORDER_COLOR;
    border-right: 2px solid $FOCUSED_BORDER_COLOR;
    border-bottom: 2px solid $FOCUSED_BORDER_COLOR;
    border-bottom-left-radius: $DEFAULT_INPUT_WITH_RESULTS_BORDER_RADIUS;
    border-bottom-right-radius: $DEFAULT_INPUT_WITH_RESULTS_BORDER_RADIUS;
    filter: $DROP_SHADOW;
    animation: fade-in .2s forwards ease;
    opacity: 0;
    z-index: 10;
}

.defaultInputSearchResult { 
    height: 40px;
    padding-left: $DEFAULT_INPUT_PADDING;
    background-color: $DEFAULT_PAGE_BG_COLOR;
    transition: .2s all ease;
    display: flex;
    align-items: center;
}

.defaultInputSearchResult:hover {
    background-color: $DEFAULT_DROPDOWN_FOCUS_COLOR;
    cursor: pointer;
}

.defaultInputSearchResultText {

}

.defaultInputSearchResults::-webkit-scrollbar
{
	width: 12px;
}


.defaultInputSearchResults::-webkit-scrollbar-track {
}

/* Handle */
.defaultInputSearchResults::-webkit-scrollbar-thumb {
    background: $DEFAULT_ACCENT_COLOR;
    height: 20px;
}

/* Handle on hover */
.defaultInputSearchResults::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@keyframes fade-in {
    0% { opacity: 0 }
    100% { opacity: 1 }
}