@import '../../styles.scss';

.chromeExtensionTokenModalHeader {
    font-size: 1.3em;
    margin-bottom: 20px;
}

.chromeExtensionTokenModalBlurb {
    font-family: $DEFAULT_FONT;
    margin: 0px;
    font-size: 0.9em;
    margin-bottom: 20px;
}

.chromeExtensionTokenLink {
    text-decoration: none;
    color: $DEFAULT_LINK_COLOR;
    cursor: pointer;
}

.chromeExtensionTokenStoreMsg {
    margin-bottom: 5px;
}

.chromeExtensionTokenInputContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.chromeExtensionTokenInput {
    flex: 1;
    margin-right: 10px;
}

.chromeExtensionTokenCloseButton {
    margin-right: 10px;
}

.chromeExtensionTokenGenerateButton {

}